$(document).ready(function () {
  // 견적문의 추가 정보
  $("div.toggle").click(function () {
    $(".toggle-data").toggle("2000");
  });
  // sku 설명
  // $("img.sku").hover(function () {
  //   $(".sku-detail").toggle();
  // });
  $("img.sku").click(function () {
    $(".sku-detail").toggle();
  });
  $("img.sku-detail").click(function () {
    $(".sku-detail").toggle();
  });

  $("p#inquiry").click(function () {
    runAction("down");
  });

  $('div.inquiry-btn').click(function() {
    submitForm();
  });

  $('div.subscribe-btn').click(function() {
    subscribeNewsLetter();
  });


  $('div.inquiry-en-btn').click(function() {
    submitEnForm();
  });

  $('div.subscribe-en-btn').click(function() {
    subscribeEnNewsLetter();
  });

  $('div.lang').click(function() {
    $('.lang-select').toggle();
  });

  $('div.lang-select-inner > p').click(function() {
    let lang = $(this).data('lang');
    if(lang == 'ko'){
      window.location.href = '/ko';
    }else{
      window.location.href = '/en';
    }
  });

  // utm_medium 파라미터 체크
  if (getURLParameter('utm_medium') === 'cpc') {
    $('html, body').animate({
      scrollTop: $('#section-1').offset().top
    }, 1000);
  }

  let currentDirection = ""; // 현재의 방향을 나타내는 변수
  let lastScrollTop = 0; // 방향을 구하기 위해 사용되는 변수

  $(window).scroll(function (event) {

    if (getURLParameter('utm_medium') === 'cpc') {
      return;
    }

    let currentPos = $(this).scrollTop();
    if (currentPos > lastScrollTop) {
      // 아래로 스크롤 중
      if (currentDirection != "down") {
        // 마지막 방향 확인
        currentDirection = "down";
        runAction("down");
      }
    } else {
      // 위로 스크롤 중
      if (currentDirection != "up") {
        // 마지막 방향 확인
        currentDirection = "up";
        runAction("up");
      }
    }
    lastScrollTop = currentPos; // 방향을 구하기 위해 마지막 스크롤 지점을 저장
  });


  function getURLParameter(name) {
    return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.search)||[,""])[1].replace(/\+/g, '%20'))||null;
  }

  function runAction(event) {
    let scrollT = $(this).scrollTop(); // 스크롤바의 상단위치
    let scrollH = $(this).height(); // 스크롤바를 갖는 div의 높이
    let contentH = $("#main").height(); // 문서 전체 내용을 갖는 div의 높이
    let headerH = $("header").height(); // header의 높이
    // console.log(scrollT, scrollH, contentH);
    if (event == "up") {
      // console.log("up");
      // if (scrollT >= contentH - headerH)
      //   $("html, body").animate({ scrollTop: 0 }, 500);
    } else {
      if (scrollT < headerH)
        //console.log("down");
        $("html, body").animate({ scrollTop: contentH - headerH }, 500);
    }
  }

  function submitForm() {
    let companyName = $('#companyName').val();
    let manager = $('#manager').val();
    let url = $('#url').val();
    let phone = $('#phone').val();
    let category = $('#category').val();
    let email = $('#email').val();
    let amount = $('#amount').val();
    let sku = $('#sku').val();
    let barcode = $('input[name=barcode]:checked').val();
    let packingType = $('#packingType').val();

    if(companyName === ""){
      alert('회사명을 입력해주세요.');
      $('#companyName').focus();
      return;
    }

    if(manager === ""){
      alert('담당자명을 입력해주세요.');
      $('#manager').focus();
      return;
    }

    // if(url === ""){
    //   alert('대표 판매처 URL을 입력해주세요.');
    //   $('#url').focus();
    //   return;
    // }

    if(phone === ""){
      alert('딤딩자 연락처를 입력해주세요.');
      $('#phone').focus();
      return;
    }

    $.ajax({
      method: "POST",
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      url: baseUrl+"sumbitInquiry",
      data: {
        companyName: companyName,
        manager: manager,
        url: url,
        email: email,
        phone: phone,
        category: category,
        amount: amount,
        sku: sku,
        barcode: barcode,
        packingType: packingType
      },
    }).done(function( data ) {
      if(data.status == 200){
        alert('1:1 견적이 제출 되었습니다.');
        location.reload();
      }else{
        alert(data.message);
      }
    });
  }
  
  function subscribeNewsLetter() {
    let email = $('#newsletterEmail').val();

    if(email == ''){
      alert('이메일을 입력해주세요.');
      $('#newsletterEmail').focus();
      return;
    }

    $.ajax({
      method: "POST",
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      url: baseUrl+"subscribeNewsLetter",
      data: {
        email: email,
      },
    }).done(function( data ) {
      if(data.status == 200){
        alert('뉴스레터 구독신청이 완료되었습니다.');
        location.reload();
      }else{
        alert(data.message);
      }
    });
  }

  function submitEnForm() {
    let companyName = $('#companyName').val();
    let manager = $('#manager').val();
    let url = $('#url').val();
    let phone = $('#phone').val();
    let category = $('#category').val();
    let email = $('#email').val();
    let amount = $('#amount').val();
    let sku = $('#sku').val();
    let barcode = $('input[name=barcode]:checked').val();
    let packingType = $('#packingType').val();

    if(companyName === ""){
      alert('Please enter the company name.');
      $('#companyName').focus();
      return;
    }

    if(manager === ""){
      alert('Please enter the name of the contact person.');
      $('#manager').focus();
      return;
    }

    if(email === ""){
      alert('Please enter the email address of the contact person.');
      $('#phone').focus();
      return;
    }

    $.ajax({
      method: "POST",
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      url: baseUrl+"sumbitInquiry",
      data: {
        companyName: companyName,
        manager: manager,
        url: url,
        email: email,
        phone: phone,
        category: category,
        amount: amount,
        sku: sku,
        barcode: barcode,
        packingType: packingType
      },
    }).done(function( data ) {
      if(data.status == 200){
        alert('1:1 quotation request has been submitted.');
        location.reload();
      }else{
        alert(data.message);
      }
    });
  }

  function subscribeEnNewsLetter() {
    let email = $('#newsletterEmail').val();

    if(email == ''){
      alert('Please enter your email address.');
      $('#newsletterEmail').focus();
      return;
    }

    $.ajax({
      method: "POST",
      headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
      },
      url: baseUrl+"subscribeNewsLetter",
      data: {
        email: email,
      },
    }).done(function( data ) {
      if(data.status == 200){
        alert('Your newsletter subscription request has been completed.');
        location.reload();
      }else{
        alert(data.message);
      }
    });
  }
});
